<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Дисциплины иностранных университетов</h4>

      <!-- add discipline button -->
      <div class="my-4">
        <Button icon="pi pi-plus" label="Добавить дисциплину" class="p-button-rounded p-button-secondary"
                @click="openDisciplineModal()"/>
      </div>
      <!-- end add discipline button -->

      <!-- list of disciplines -->
      <div v-if="foreignDiscipline.disciplines.length">
        <!-- table start -->
        <div class="table-responsive-md mt-4">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Код дисциплины</th>
              <th scope="col">Название дисциплины (на английском)</th>
              <th scope="col">Название дисциплины (на русском)</th>
              <th scope="col">Название дисциплины (на казахском)</th>
              <th scope="col">Редактировать</th>
              <th scope="col">Удалить</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item,index) in foreignDiscipline.disciplines" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.code }}</td>
              <td>{{ item.name_en}}</td>
              <td>{{ item.name_ru}}</td>
              <td>{{ item.name_kz }}</td>
              <td class="text-center">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
                        @click="openDisciplineModal(item.id)"/>
              </td>
              <td class="text-center">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        @click="confirmDeleteEducationDisciplineModal(item.id)"/>
              </td>

            </tr>
            </tbody>
          </table>
        </div>
        <!-- table end -->

        <nav v-if="foreignDiscipline.pageCount" class="d-flex justify-content-center"
             aria-label="Page navigation example">
          <Paginate
              v-model="page"
              :page-count="foreignDiscipline.pageCount"
              :click-handler="paginateHandler"
              :prev-text="'Предыдущий'"
              :next-text="'Следующий'"
              :container-class="'pagination'"
              :page-class="'page-item'">
          </Paginate>
        </nav>
      </div>
      <!-- list of disciplines -->


      <!-- delete discipline modal -->
      <ConfirmDialog group="deleteDisciplineDialog"></ConfirmDialog>
      <!-- delete discipline modal -->


      <!-- add or update discipline modal -->
      <Dialog :header="disciplineModel?.id?'Обновление дисциплины':'Добавление дисциплины'"
              v-model:visible="displayDisciplineModal"
              :style="{width: '98%', maxWidth: '1000px'}" :modal="true" :closable="false">
        <div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Иностранный ВУЗ</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      v-model="disciplineModel.foreign_university_id">
                <option v-for="(university, universityIndex) in foreignDiscipline.universities"
                        :value="university.id"
                        :key="universityIndex">
                  {{university.university_name}} ({{university.university_city}}, {{university.university_country}})
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="education_direction" class="col-md-3 col-form-label">
              Уровень образования
            </label>
            <div class="col-md-9">
              <select class="form-control form-select" id="education_direction"
                      v-model="disciplineModel.study_level_id">
                <option v-for="(studyLevel, studyLevelIndex) in educationdiscipline_form.studyLevel"
                        :value="studyLevel.id" :key="studyLevelIndex">
                  {{studyLevel.name}}
                </option>
              </select>
            </div>
          </div>


          <div class="form-group row mt-4">
            <label for="education_lang" class="col-md-3 col-form-label">
              Язык дисциплины
            </label>
            <div class="col-md-9">
              <select class="form-control form-select" id="education_lang"
                      v-model="disciplineModel.education_language">
                <option v-for="(item, index) in educationdiscipline_form.languages" :value="item.id" :key="index">
                  {{item.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="education_speciality_credit" class="col-md-3 col-form-label">
              Кредит
            </label>
            <div class="col-md-9">
              <input id="education_speciality_credit" type="number" class="form-control"
                     placeholder="Кредит"
                     v-model="disciplineModel.credit">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="name_en" class="col-md-3 col-form-label">
              Название дисциплины (на английском)
            </label>
            <div class="col-md-9">
              <input id="name_en" type="text" class="form-control"
                     placeholder="Название дисциплины (на английском)"
                     v-model="disciplineModel.name_en">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="name_ru" class="col-md-3 col-form-label">
              Название дисциплины (на русском)
            </label>
            <div class="col-md-9">
              <input id="name_ru" type="text" class="form-control"
                     placeholder="Название дисциплины (на русском)"
                     v-model="disciplineModel.name_ru">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="name_kz" class="col-md-3 col-form-label">
              Название дисциплины (на казахском)
            </label>
            <div class="col-md-9">
              <input id="name_kz" type="text" class="form-control"
                     placeholder="Название дисциплины (на казахском)"
                     v-model="disciplineModel.name_kz">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="education_speciality_code" class="col-md-3 col-form-label">
              Код дисциплины
            </label>
            <div class="col-md-9">
              <input id="education_speciality_code" type="text" class="form-control"
                     placeholder="Код дисциплины"
                     v-model="disciplineModel.code">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="description" class="col-md-3 col-form-label">Описание дисциплины</label>
            <div class="col-md-9">
              <textarea id="description" type="text" class="form-control" placeholder="Описание дисциплины"
                        v-model="disciplineModel.description"></textarea>
            </div>
          </div>


          <div class="form-group row mt-4">
            <label for="knowledge" class="col-md-3 col-form-label">Знания</label>
            <div class="col-md-9">
              <textarea id="knowledge" type="text" class="form-control" placeholder="Знания"
                        v-model="disciplineModel.knowledge"></textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="skills" class="col-md-3 col-form-label">Навыки</label>
            <div class="col-md-9">
              <textarea id="skills" type="text" class="form-control" placeholder="Навыки"
                        v-model="disciplineModel.skills"></textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="abilities" class="col-md-3 col-form-label">Умения</label>
            <div class="col-md-9">
              <textarea id="abilities" type="text" class="form-control" placeholder="Умения"
                        v-model="disciplineModel.abilities"></textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="recommendation" class="col-md-3 col-form-label">Рекомендации </label>
            <div class="col-md-9">
              <textarea id="recommendation" type="text" class="form-control" placeholder="Рекомендации"
                        v-model="disciplineModel.recommendation"></textarea>
            </div>
          </div>

        </div>
        <template #footer>
          <Button label="Отмена" icon="pi pi-times" @click="closeDisciplineModal" class="p-button-text"/>
          <Button :label="disciplineModel?.id?'Сохранить':'Создать'" icon="pi pi-check" autofocus
                  :disabled="btnSaveDisabled"
                  @click="saveForeignDiscipline"/>
        </template>
      </Dialog>
      <!-- end add or update discipline modal -->

    </div>
  </div>
</template>


<script>

  import Paginate from '@/components/common/Paginate.vue'
  import {mapGetters, mapActions, mapMutations, mapState} from 'vuex'

  export default {
    name: "DisciplinesOfForeignUniversities",
    components: {Paginate},
    data() {
      return {
        loading: true,
        page: 1,
        displayDisciplineModal: false,
        disciplineModel: {},
        btnSaveDisabled: false
      }
    },
    computed: {
      ...mapState('educationdiscipline', ['educationdiscipline_form']),
      ...mapState('foreignDiscipline', ['foreignDiscipline']),
    },
    methods: {
      ...mapActions('educationdiscipline', ['GET_DEPARTMENT_NAMES', 'GET_STUDY_LEVEL_NAMES', 'GET_CREDIT_TIME_TYPE']),
      ...mapActions('foreignDiscipline', ['GET_FOREIGN_DISCIPLINES', 'POST_FOREIGN_DISCIPLINE', 'PUT_FOREIGN_DISCIPLINE', 'DELETE_FOREIGN_DISCIPLINE',
        'GET_FOREIGN_UNIVERSITIES']),
      openDisciplineModal(id = 0) {
        if (id) {
          this.disciplineModel = {...this.foreignDiscipline.disciplines.find(i => i.id == id)}
        } else {
          this.disciplineModel = {
            foreign_university_id: undefined,
            study_level_id: 0,
            education_language: 39,
            credit: undefined,
            name_en: '',
            name_ru: '',
            name_kz: '',
            code: '',
            description: '',
            knowledge: '',
            skills: '',
            abilities: '',
            recommendation: ''
          }
        }

        console.log(this.disciplineModel, 'disciplineModel', id)

        this.displayDisciplineModal = true
      },
      closeDisciplineModal() {
        this.displayDisciplineModal = false
      },
      async paginateHandler(page) {
        this.page = page
        // this.$router.push(`${this.$route.path}?page=${this.page}`)
        await this.getForeignDisciplines(this.page)
      },
      confirmDeleteEducationDisciplineModal(id) {
        this.$confirm.require({
          group: 'deleteDisciplineDialog',
          message: 'Вы точно хотите удалить дисциплину?',
          header: 'Удаление дисциплины',
          icon: 'pi pi-info-circle',
          acceptClass: 'p-button-danger',
          accept: async () => {
            const {success} = await this.DELETE_FOREIGN_DISCIPLINE(id)
            if (success) {
              await this.getForeignDisciplines(this.page)
              this.$message({title: 'Удалено'})
            }
          },
          reject: () => {
            this.$messageInfo({title: 'Отменено'})
          }
        });
      },
      async saveForeignDiscipline() {
        this.btnSaveDisabled = true
        let res = {success: false}
        let title = 'Успешно создано'

        if (this.disciplineModel?.id) {
          res = await this.PUT_FOREIGN_DISCIPLINE(this.disciplineModel)
          title = 'Успешно сохранено'
        } else {
          res = await this.POST_FOREIGN_DISCIPLINE(this.disciplineModel)
        }

        if (res.success) {
          await this.getForeignDisciplines(this.page)
          this.$message({title})
          this.closeDisciplineModal()
        }
        this.btnSaveDisabled = false
      },
      async getForeignDisciplines(page = 1) {
        await this.GET_FOREIGN_DISCIPLINES({page})
      }
    },
    async mounted() {
      await this.GET_FOREIGN_UNIVERSITIES()
      await this.GET_STUDY_LEVEL_NAMES()

      await this.getForeignDisciplines()

      this.loading = false
    }
  }
</script>

<style scoped>
  .big-checkbox {
    width: 20px;
    height: 20px;
  }
</style>